*, :after, :before {
  box-sizing: border-box;
}

@font-face {
  font-family: Marker;
  src: url("pm.2fd02e52.ttf");
}

:root {
  --color-text: #131313;
  --color-bg: #fff;
  --color-link: #000;
  --color-link-hover: #131313;
  --padding-sides: 2rem;
  --padding-row: 1rem;
  --color-row-border: #f0f0f0;
  --color-bg-row-hover: #f0f0f0;
  --color-text-alt: #000;
  --image-gap: 1vw;
  --image-gap-large: 2vw;
  --img-size: 5vw;
  --img-size-large: 14vw;
  font-size: 14px;
}

body {
  color: var(--color-text);
  background-color: var(--color-bg);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  margin: 0;
  font-family: auger-mono, monospace;
  font-weight: 300;
  position: relative;
  overflow-x: hidden;
}

.oh {
  position: relative;
  overflow: hidden;
}

.oh__inner {
  will-change: transform;
  display: inline-block;
}

.js .loading:before, .js .loading:after {
  content: "";
  z-index: 1000;
  position: fixed;
}

.js .loading:before {
  width: 100%;
  height: 100%;
  background: var(--color-bg);
  top: 0;
  left: 0;
}

.js .loading:after {
  width: 60px;
  height: 60px;
  opacity: .4;
  background: var(--color-link);
  border-radius: 50%;
  margin: -30px 0 0 -30px;
  animation: .7s linear infinite alternate forwards loaderAnim;
  top: 50%;
  left: 50%;
}

@keyframes loaderAnim {
  to {
    opacity: 1;
    transform: scale3d(.5, .5, 1);
  }
}

a {
  color: var(--color-link);
  cursor: pointer;
  outline: none;
  text-decoration: none;
}

.hover-line {
  position: relative;
  overflow: hidden;
}

.hover-line:before {
  content: "";
  height: 1px;
  width: 100%;
  transform-origin: 0%;
  background: currentColor;
  transition: transform .3s;
  position: absolute;
  top: 92%;
}

.hover-line:hover:before {
  transform-origin: 100%;
  transform: scaleX(0);
}

a:hover {
  color: var(--color-link-hover);
  outline: none;
  text-decoration: none;
}

a:focus {
  background: #d3d3d3;
  outline: none;
}

a:focus:not(:focus-visible) {
  background: none;
}

a:focus-visible {
  background: none;
  outline: 2px solid red;
}

.unbutton {
  font: inherit;
  cursor: pointer;
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
}

.unbutton:focus {
  outline: none;
}

.frame {
  width: 100%;
  padding: 3rem var(--padding-sides);
  grid-row-gap: 1rem;
  grid-template: "title"
                 "prevdemo"
                 "sponsor"
                 / 1fr;
  justify-content: start;
  place-items: end start;
  display: grid;
}

.frame__title {
  grid-area: title;
  line-height: 1;
  display: flex;
}

.frame__title-main {
  text-transform: none;
  margin: 0;
  font-family: neue-haas-grotesk-display, sans-serif;
  font-size: 1.953rem;
  font-weight: 400;
}

.frame__title-back {
  align-items: flex-end;
  display: flex;
  position: relative;
}

.frame__title-back span {
  display: none;
}

.frame__title-back svg {
  fill: currentColor;
}

.frame__prevdemo {
  grid-area: prevdemo;
}

.intro {
  padding: 1rem var(--padding-sides) 3rem;
}

.intro p {
  max-width: 860px;
}

.cover {
  background: var(--color-bg-row-hover);
  width: 100%;
  height: 0;
  opacity: 0;
  pointer-events: none;
  z-index: 10;
  will-change: height, top;
  position: fixed;
  left: 0;
}

.content {
  z-index: 100;
  border-bottom: 1px solid var(--color-row-border);
  position: relative;
}

.row {
  z-index: 1;
  color: var(--color-text);
  cursor: pointer;
  grid-template-rows: var(--img-size);
  grid-column-gap: 5vw;
  padding: var(--padding-row) var(--padding-sides);
  border-top: 1px solid var(--color-row-border);
  grid-template-columns: auto 1fr;
  align-items: center;
  transition: background-color .3s ease-out, border-color .3s ease-out;
  display: grid;
  position: relative;
}

.row:hover {
  background-color: var(--color-bg-row-hover);
}

.row--current {
  z-index: 11;
  transition: border-color .3s ease-out;
}

.cell {
  position: relative;
}

.cell__title, .preview__item-title {
  white-space: nowrap;
  margin: 0;
  font-family: neue-haas-grotesk-display, sans-serif;
  font-size: clamp(1.953rem, 4vw, 3.052rem);
  font-weight: 400;
  line-height: 1;
  position: relative;
}

.cell__title--switch {
  font-family: lores-22-serif, sans-serif;
  font-weight: 700;
}

.cell__title {
  grid-template-rows: 100% 100%;
  display: grid;
}

.cell--images {
  grid-gap: var(--image-gap);
  grid-auto-columns: auto;
  grid-auto-flow: column;
  place-content: center end;
  margin-left: auto;
  display: grid;
}

.cell__img {
  width: var(--img-size);
  will-change: transform, opacity;
  opacity: 0;
  grid-template-rows: auto auto;
  grid-template-columns: 100%;
  display: grid;
  position: relative;
}

.cell__img-inner {
  aspect-ratio: 1;
  width: 100%;
  border-radius: calc(var(--image-gap) / 3);
  background-position: 50%;
  background-size: cover;
}

.cell__img-title {
  max-width: 100%;
  color: var(--color-text-alt);
  height: 0;
  opacity: 0;
  white-space: nowrap;
  will-change: opacity, transform;
  margin: 0;
  font-size: 1rem;
  font-weight: 300;
  display: none;
}

.page-footer {
  padding: 10vw 10vw 5vw;
}

.page-footer__text {
  max-width: 860px;
  margin: 1.5rem auto;
  line-height: 1.5;
}

.page-footer__credits {
  text-align: center;
  padding-top: 10vh;
}

.preview {
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 200;
  position: fixed;
  top: 0;
  left: 0;
}

.preview__close {
  cursor: pointer;
  opacity: 0;
  padding: 2rem;
  font-family: sans-serif;
  font-size: 2rem;
  font-weight: 400;
  line-height: 1;
  position: absolute;
  top: 0;
  right: 0;
}

.preview__close--show {
  pointer-events: auto;
}

.preview__item {
  width: 100%;
  height: 100%;
  grid-gap: 10vh;
  height: 0;
  opacity: 0;
  align-content: center;
  place-items: center;
  display: grid;
  overflow: hidden;
}

.preview__item--current {
  pointer-events: auto;
  height: 100vh;
  opacity: 1;
}

.preview__item-title {
  font-family: lores-22-serif, sans-serif;
  font-size: clamp(1.563rem, 6vw, 3.815rem);
  font-weight: 700;
}

.grid {
  max-width: 1200px;
  width: 100%;
  grid-gap: var(--image-gap-large);
  --img-size: var(--img-size-large);
  grid-template-columns: repeat(1, var(--img-size));
  justify-content: center;
  justify-items: center;
  display: grid;
  position: relative;
}

.grid .cell__img {
  width: var(--img-size-large);
}

.text-hover {
  color: #fff;
  text-align: center;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.overlay {
  height: 100%;
  width: 100%;
  opacity: 0;
  background-color: #008cba;
  transition: all .5s;
  position: absolute;
  inset: 0;
}

.cell__img:hover .overlay {
  opacity: 1;
}

.mark {
  color: #fff;
  background: url("https://media.giphy.com/media/5tvOtygPxPu40RPGc2/giphy-downsized-large.gif");
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  opacity: .75;
  letter-spacing: 2pt;
  background-position-x: 91px;
  font-family: Marker;
  font-size: 5vh;
  line-height: .9;
  transition: all .3s;
}

@media screen and (min-width: 61em) {
  .frame {
    grid-column-gap: 2rem;
    grid-template: "title prevdemo sponsor"
    / auto auto 1fr;
  }

  .grid {
    max-width: 1200px;
    width: 100%;
    grid-gap: var(--image-gap-large);
    --img-size: var(--img-size-large);
    grid-template-columns: repeat(4, var(--img-size));
    grid-template-rows: repeat(2, var(--img-size));
    justify-content: center;
    justify-items: center;
    display: grid;
    position: relative;
  }

  :root {
    --padding-sides: 4rem;
    --padding-row: 2rem;
  }

  .grid .cell__img {
    width: var(--img-size-large);
  }

  .mark {
    color: #fff;
    background: url("https://media.giphy.com/media/5tvOtygPxPu40RPGc2/giphy-downsized-large.gif");
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    opacity: .75;
    letter-spacing: 2pt;
    background-position-x: 91px;
    font-family: Marker;
    font-size: 10vh;
    line-height: .9;
    transition: all .3s;
  }
}

/*# sourceMappingURL=index.cea116dc.css.map */
